<template>
  <div class="list row">
    <h2>Korisnici</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      />
      <button
        type="button"
        class="btn btn-success bi bi-plus"
        @click="addData"
      />
    </div>
    <data-table
      url="users"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      ref="datatable"
      @yes="deleteData"
      name="Users"
    >
    </data-table>
  </div>
</template>

<script>
import UserService from "../../services/user.service";
import DataTable from "../widgets/DataTable.vue";
import UsersForm from "./UsersForm.vue";
import PasswordForm from "./PasswordForm.vue";
import utils from "../../utils/common";

export default {
  name: "tax-rates",
  components: {
    DataTable,
  },
  data() {
    return {
      columns: ["username", "firstname", "lastname", "email", "status", "edit"],
      headers: {
        username: "Korisnicko ime",
        firstname: "Ime",
        lastname: "Prezime",
        status: "Status",
        edit: "",
      },
      templates: {},
      actions: {
        edit: { commp: UsersForm, icon: "bi-pencil" },
        delete: {
          action: this.deleteData,
          icon: "bi-trash",
          color: "danger",
        },
        // print: {
        //   icon: "bi-printer",
        //   color: "success",
        //   action: () => console.log("PRINT!"),
        // },
        changePass: {
          icon: "bi-key",
          color: "success",
          action: (data) => this.changePassword(data),
        },
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$store.commit("app/showModal", {
        modal: UsersForm,
        params: { data: {}, title: "Dodaj novog korisnika" },
      });
    },
    deleteData(data) {
      utils.showDialog(
        data,
        "Da li ste sigurni da zelite da deaktivirate korisnika?",
        "Deaktiviranje korisnika: " + data.username,
        () => {
          UserService.update(data.id, { id:data.id, status: "Inactive" })
            .then(() => {
              this.$store.commit("app/showAlert", {
                type: "success", 
                message: "Korisnik je deaktiviran!",
              });
              this.$store.commit("app/hideModal");
            })
            .catch((e) => {
              this.$store.commit("app/showAlert", {
                type: "danger",
                message: e,
              });
            });
        }
      );
    },
    changePassword(data) {
      this.$store.commit("app/showModal", {
        modal: PasswordForm,
        params: {
          title: "Promena passworda: ",
          data,
        },
      });
    },
  },
};
</script>

<style scoped>
.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}
</style>
