<template>
  <data-form2
    :data="data"
    :formFields="formFields"
    minheight="450px"
    @save="saveData"
    @cancel="cancel"
  />
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import UserService from "../../services/user.service";

export default {
  name: "tutorial-form",
  props: ["data"],
  components: {
    DataForm2,
  },
  data() {
    return { message: "" };
  },
  computed: {
    formFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [
            { username: { label: "Username", type: "text" } },
            { email: { label: "Email", type: "text" } },
          ],
          // password: { label: "Password", type: "password" },
          [
            { firstname: { label: "Ime", type: "text" } },
            { lastname: { label: "Prezime", type: "text" } },
          ],
          [
            {
              status: {
                label: "Status",
                type: "dropdown",
                list: [
                  { id: "Active", name: "Aktivan" },
                  { id: "Inactive", name: "Neaktivan" },
                ],
              },
            },{}
          ],
          [
            {
              stations: {
                label: "Poslovnice čijim dokumentima korisnik ima pristup",
                labelField: "code",
                type: "dropdown",
                description:
                  "Izaberite jednu ili vise poslovnica čije dokumente user ima pravo da vidi i edituje. Ako nema izabranih poslovnica, user ima pravo da radi sa svim dokumentima!",
                dropdownHeight: "150px",
                multiple: true,
                url: "/stations?limit=100000&ascending=1",
              },
            },
          ],
        ],
      };
    },
  },
  methods: {
    saveData(newData) {
      if (newData && newData.id) {
        this.update(newData);
      } else {
        this.create(newData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      newData.stations =
        newData.stations &&
        newData.stations.length > 0 &&
        newData.stations[0].id
          ? newData.stations.map((v) => v.id)
          : newData.stations;
      delete newData.password;
      UserService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "User je promenjen!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      newData.stations =
        newData.stations &&
        newData.stations.length > 0 &&
        newData.stations[0].id
          ? newData.stations.map((v) => v.id)
          : newData.stations;
      UserService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "User je kreiran. Upisite password!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>