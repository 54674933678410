<template>
  <data-form2
    :data="getFormData()"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
  />
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import UserService from "../../services/user.service";

export default {
  name: "password-form",
  props: ["data"],
  components: {
    DataForm2,
  },
  data() {
    return { message: "" };
  },
  computed: {
    formFields() {
      return {
        rows: [
          [
            {
              hint: {
                label:
                  "Minimalna dužina 8. Minimum jedno veliko slovo, minimum jedan broj!",
                type: "h6",
              },
            },
          ],
          [],
          [],
          [],
          [],
          [
            {
              password: {
                label: "Šifra",
                type: "password",
              },
            },
            { id: { type: "hidden" } },
          ],
          [{ passwordCnf: { label: "Potvrdi šifru", type: "password" } }, {}],
        ],
      };
    },
  },
  methods: {
    getFormData() {
      return { ...this.data, password: null };
    },
    saveData(newData) {
      this.update(newData);
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      if (!newData.password) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Šifra je obavezna!",
        });
        return;
      }
      if (newData.password != newData.passwordCnf) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Greška pri potvrdi šifre!",
        });
        return;
      }
      const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
      if (!regex.test(newData.password)) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message:
            "Loš format. Minimalna dužina 8. Minimum jedno veliko slovo, minimum jedan broj!!",
        });
        return;
      }
      newData.stations =
        newData.stations &&
        newData.stations.length > 0 &&
        newData.stations[0].id
          ? newData.stations.map((v) => v.id)
          : newData.stations;
      UserService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Password je promenjen!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>